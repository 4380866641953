import * as React from "react"
import { LayoutDashboard } from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
    <LayoutDashboard>
        <Seo title="Home" />
    </LayoutDashboard>
)

export default IndexPage
